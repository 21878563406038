import "./Resume.css";

export default function Resume() {
  return (
    <>
      <div className="resume-container" id="resume">
        {/* <button
          className="view-resume"
          onClick={() =>
            window.open(
              "https://res.cloudinary.com/alienora/image/upload/v1617816108/Screen_Shot_2021-04-07_at_1.20.12_PM_ieapzg.png",
              "_blank"
            )
          }
        >
          View Resume
        </button> */}
        
          <a href="https://www.linkedin.com/in/noratulchina/">
            <img
              className="LI"
              src="https://res.cloudinary.com/alienora/image/upload/v1617569964/LinkedIN-512_eirh46.png"
              alt="linkedin"
            ></img>
          </a>

          <a href="https://github.com/Nora-ai">
            <img
              className="GH"
              src="https://res.cloudinary.com/alienora/image/upload/v1617569563/288981919427_f45f04edd92902a96859_512_mwyfba.png"
              alt="github"
            ></img>
          </a>
      </div>
    </>
  );
}
