import './Header.css'

export default function Header() {
    return(<>
        
        <div className="linear-gradient" id="about">
            <p className="ellenora">Ellenora Tulchina</p>
            <p className="full-stack">Full-Stack Software Engineer</p>
          
        </div>
    </>)

    }
