import './Skills.css'

export default function Skills() {
    return(<>
    <h2 className="skills-title" id="skills">SKILLS</h2>
    <div className="skills-icons">
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617811751/10403104782126634882_xueqxm.png" alt="html"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617811898/css3_01_ajvs0z.png" alt="css"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812169/javascript-20-555998_llvlxp.png" alt="javascript"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617811969/react-native-555397_yqskwr.png" alt="react"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812226/603784_nodejs_512x512_drextu.png" alt="node"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1621342818/expressjs_y4avxj.svg" alt="express"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1621341686/mongodb-logo-node-js-computer-icons-png-favpng-tnV7G59kBSVG4RbJcmbLwbXHt_l5mldj.jpg" alt="mongodb"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812282/Git-Icon-Black_qjmww1.png" alt="git"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812288/25231_qc38e6.png" alt="github"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812315/heroku_zqdhdc.png" alt="heroku"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812350/cib-postman_llxhym.png" alt="postman"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1621343434/ruby-logo_omeau1.png" alt="ruby"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812823/93005_on_395x512_oois1r.png" alt="rails"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1617812897/adobe-photoshop-icon-256_au53za.png" alt="photoshop"></img>
        <img src="https://res.cloudinary.com/alienora/image/upload/v1621344028/adobexd_aemmdw.png" alt="adobexd"></img>
    </div>
     </>)
}