import './App.css';
import Header from './components/Header.jsx'
// import Nav from './components/Nav.jsx'
import About from './components/About.jsx'
// import Icons from './components/Icons.jsx'
import Projects from './components/Projects.jsx'
import Resume from './components/Resume.jsx'
import Skills from './components/Skills.jsx'
import Contact from './components/Contact.jsx'

function App() {
  return (
    <div className="App">
      
      <Header />
      <About />
      <Projects />
      <Skills />
      
      <Contact />
      <Resume />
      
    </div>
  );
}

export default App;
