import "./Projects.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Card, Accordion } from "react-bootstrap";

export default function Projects() {
  return (
    <>
      <h2 className="projects-title" id="projects">
        PROJECTS
      </h2>

      <Accordion className="accordion">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            className="projects-header"
          >
            Wavculture
          </Accordion.Toggle>

          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <div className="wavculture-div">
                <p className="wavculture-title">Wavculture Music Directory</p>
                <p className="wavculture-desc">
                  Custom Wireframes - Development in Progress
                </p>
                <video
                  autoPlay="autoPlay"
                  loop="loop"
                  muted
                  className="wavculture"
                >
                  <source
                    src={`https://res.cloudinary.com/alienora/video/upload/v1621789427/wavculture_caeobo.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
                <div className="site-links">
                  <a href="https://github.com/Nora-ai/Wavculture">Github</a>
                  <a href="https://whimsical.com/wavculture-K6pr7AddzJuBsbjDRCVYsf">
                    Wireframes
                  </a>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            Mindfront
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <div className="mindfront-div">
                <p className="mindfront-title">MindFront Blog</p>
                <p className="mindfront-desc">
                  Built with Ruby on Rails and React
                </p>
                <video
                  autoPlay="autoPlay"
                  loop="loop"
                  muted
                  className="mindfront"
                >
                  <source
                    src={`https://res.cloudinary.com/alienora/video/upload/v1621372737/mindfront_portfolio_v31b1f.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
                <div className="site-links">
                  <a href="https://github.com/Nora-ai/MindFront">Github</a>
                  <a href="https://mindfront.netlify.app/">Website</a>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            Bed Bug Finder
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <div className="bed-bug-div">
                <p className="bb-title">Bed Bug Finder</p>
                <p className="bb-desc">Built with Vanilla Javascript</p>
                <video
                  autoPlay="autoPlay"
                  loop="loop"
                  muted
                  className="bed-bug"
                >
                  <source
                    src={`https://res.cloudinary.com/alienora/video/upload/v1617583372/BedBugKenBurnsRGB_nqu080.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
                <div className="site-links">
                  <a href="https://github.com/Nora-ai/Bed-Bug-Finder">Github</a>
                  <a href="https://nora-ai.github.io/Bed-Bug-Finder/">
                    Website
                  </a>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            SubBusters
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <div className="sub-div">
                <p className="sub-title">SubBusters Subscription Tracker</p>
                <p className="sub-desc">
                  Built with Express, MongoDB and React - collaboration
                </p>
                <video autoPlay="autoPlay" loop="loop" muted className="sub">
                  <source
                    src={`https://res.cloudinary.com/alienora/video/upload/v1617668308/SubBustersKenBurns_ofizbn.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
                <div className="site-links">
                  <a href="https://github.com/Nora-ai/SubBusters">Github</a>
                  <a href="http://subbusters.surge.sh/">Website</a>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="4">
            Meme Constructor
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="4">
            <Card.Body>
              <div className="meme-div">
                <p className="meme-title">Meme Constructor</p>
                <p className="meme-desc">Built with React.JS</p>
                <video autoPlay="autoPlay" loop="loop" muted className="meme">
                  <source
                    src={`https://res.cloudinary.com/alienora/video/upload/v1617585577/MemeIxKenBurnsRGB_b1xvym.mp4`}
                    type="video/mp4"
                  ></source>
                </video>
                <div className="site-links">
                  <a href="https://github.com/Nora-ai/Cat-Meme-Constructor">
                    Github
                  </a>
                  <a href="https://awesome-davinci-ca3d6e.netlify.app/">
                    Website
                  </a>
                </div>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </>
  );
}
