import './Contact.css'
import emailjs from 'emailjs-com'

export default function Contact() {

    function sendEmail(e) {
        e.preventDefault()
        emailjs.sendForm('service_zldmvme', 'template_ujpae4w', e.target, 'user_C8WcTnfqT745J8bEI5Gx6')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset()
    }

    return(<>
    <div className="contact-info" id="contact">
     
        
        <div className="contact-form">
        <h2>CONTACT ME</h2>
        <form onSubmit={sendEmail}>
            <div className="contact-subject-div">
                <input type="text" name="subject" placeholder="Subject" required></input>
            </div>
            <div className="contact-name-div">
                <input type="text" name="name" placeholder="Name" required></input>
            </div>
            <div className="contact-email-div">
                <input type="text" name="email" placeholder="E-mail" required></input>
            </div>
            <div className="contact-message-div">
                <input type="text" name="message" placeholder="Message" required></input>
            </div>
            <button className="contact-submit" type="submit">Submit</button>
        </form>
        </div>
    </div>
    </>)
}