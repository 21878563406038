import "./About.css";

export default function About() {
  return (
    <div className="about-container">
      {/* <div className="headshot-resume-container"> */}
        <img
          className="headshot"
          src="https://res.cloudinary.com/alienora/image/upload/v1625412804/2021_headshot_005_white_vrhdav.jpg"
          alt="nora"
        ></img>

       
      {/* </div> */}
      {/* <div className="about-text-container"> */}
        <p className="about-me-text-one">
          Multifaceted software engineer with experience in building small-scale
          full-stack web applications. For over 10 years, I worked in project
          management and business operations in the fashion industry, where I
          honed my skills working in fast paced environments and ushering large
          creative projects from initial vision to successful execution.{" "}
        </p>
        <p className="about-me-text-two">
          A lifelong learner, math enthusiast, and creative problem-solver, I
          pivoted my career to software engineering in 2020. I'm passionate
          about using technology to create new and efficient solutions for our
          world's problems. As an ambivert skilled at both listening and
          communicating, I thrive in purpose-driven environments and on
          collaborative teams.
        </p>
        <button
          className="view-resume"
          onClick={() =>
            window.open(
              "https://res.cloudinary.com/alienora/image/upload/v1627685152/ellenora_tulchina_resume_f2bi1i.png",
              "_blank"
            )
          }
        >
          View Resume
        </button>
      {/* </div> */}
    </div>
  );
}
